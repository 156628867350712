import {
  SENSOR_TWAICE_STR_CELL_VOLTAGE_SPREAD,
  SENSOR_TWAICE_STR_DCR,
  SENSOR_TWAICE_STR_EFC_CUMULATIVE,
  SENSOR_TWAICE_STR_MODULE_TEMPERATURE_SPREAD,
  SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY,
  SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_LOWER_BOUND,
  SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_UPPER_BOUND,
  STANDARD_SENSOR_BK_TWAICE_STR_CELL_VOLTAGE_SPREAD,
  STANDARD_SENSOR_BK_TWAICE_STR_DCR,
  STANDARD_SENSOR_BK_TWAICE_STR_EFC_CUMULATIVE,
  STANDARD_SENSOR_BK_TWAICE_STR_MODULE_TEMPERATURE_SPREAD,
  STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_LOWER_BOUND,
  STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_UPPER_BOUND,
  STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY,
  STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE,
  STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE_MAX,
  STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE_MIN,
  SENSOR_STR_MODULE_TEMPERATURE, SENSOR_STR_MODULE_TEMPERATURE_MAX, SENSOR_STR_MODULE_TEMPERATURE_MIN,
  SENSOR_TWAICE_STR_CELL_SOC_MAX,
  SENSOR_TWAICE_STR_CELL_SOC_MIN,
  STANDARD_SENSOR_BK_TWAICE_STR_CELL_SOC_MAX,
  STANDARD_SENSOR_BK_TWAICE_STR_CELL_SOC_MIN,
  SENSOR_TWAICE_STR_SOC,
} from '@twaice-fe/shared/constants';


export enum MeasurementAggregation {
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
}

export const KPI_STANDARD_SENSOR_BKS = {
    'soh-c' : [{
      type: 'data',
      standard_sensor_bk: STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY,
      sensor_name: SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY,
      aggregation_type: MeasurementAggregation.AVG,
    },
    {
      type: 'upperAreaConfidenceInterval',
      standard_sensor_bk: STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_UPPER_BOUND,
      sensor_name: SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_UPPER_BOUND,
      aggregation_type: MeasurementAggregation.AVG,
    },
    {
      type: 'lowerAreaConfidenceInterval',
      standard_sensor_bk: STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_LOWER_BOUND,
      sensor_name: SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_LOWER_BOUND,
      aggregation_type: MeasurementAggregation.AVG,
    }],
    'dcr' : [{
      type: 'data',
      standard_sensor_bk: STANDARD_SENSOR_BK_TWAICE_STR_DCR,
      sensor_name: SENSOR_TWAICE_STR_DCR,
      aggregation_type: MeasurementAggregation.AVG,
    }],
    'cell-voltage-spread': [{
      type: 'data',
      standard_sensor_bk: STANDARD_SENSOR_BK_TWAICE_STR_CELL_VOLTAGE_SPREAD,
      sensor_name: SENSOR_TWAICE_STR_CELL_VOLTAGE_SPREAD,
      aggregation_type: MeasurementAggregation.AVG,
    }],
    'module-temperature-spread': [{
      type: 'data',
      standard_sensor_bk: STANDARD_SENSOR_BK_TWAICE_STR_MODULE_TEMPERATURE_SPREAD,
      sensor_name: SENSOR_TWAICE_STR_MODULE_TEMPERATURE_SPREAD,
      aggregation_type: MeasurementAggregation.AVG,
    }],
    'efc': [{
      type: 'data',
      standard_sensor_bk: STANDARD_SENSOR_BK_TWAICE_STR_EFC_CUMULATIVE,
      sensor_name: SENSOR_TWAICE_STR_EFC_CUMULATIVE,
      aggregation_type: MeasurementAggregation.AVG,
    }],
    'temperature': [{
      type: 'data',
      standard_sensor_bk: STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE,
      sensor_name: SENSOR_STR_MODULE_TEMPERATURE,
      aggregation_type: MeasurementAggregation.AVG,
    },
    {
      type: 'upperAreaConfidenceInterval',
      standard_sensor_bk: STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE_MAX,
      sensor_name: SENSOR_STR_MODULE_TEMPERATURE_MAX,
      aggregation_type: MeasurementAggregation.MAX,
    },
    {
      type: 'lowerAreaConfidenceInterval',
      standard_sensor_bk: STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE_MIN,
      sensor_name: SENSOR_STR_MODULE_TEMPERATURE_MIN,
      aggregation_type: MeasurementAggregation.MIN,
    }],
    'soc': [{
      type: 'data',
      standard_sensor_bk: [STANDARD_SENSOR_BK_TWAICE_STR_CELL_SOC_MAX, STANDARD_SENSOR_BK_TWAICE_STR_CELL_SOC_MIN],
      sensor_name: SENSOR_TWAICE_STR_SOC,
      aggregation_type: MeasurementAggregation.AVG,
    },
    {
      type: 'upperAreaConfidenceInterval',
      standard_sensor_bk: [STANDARD_SENSOR_BK_TWAICE_STR_CELL_SOC_MAX],
      sensor_name: SENSOR_TWAICE_STR_CELL_SOC_MAX,
      aggregation_type: MeasurementAggregation.MAX,
    },
    {
      type: 'lowerAreaConfidenceInterval',
      standard_sensor_bk: [STANDARD_SENSOR_BK_TWAICE_STR_CELL_SOC_MIN],
      sensor_name: SENSOR_TWAICE_STR_CELL_SOC_MIN,
      aggregation_type: MeasurementAggregation.MIN,
    }]
  }

  export const KPI_UNITS = {
    'soh-c': '%',
    'dcr': 'mΩ',
    'cell-voltage-spread': 'mV',
    'module-temperature-spread': '°C',
    'efc': 'Ah'
  }
