import {
  ComponentStateInterface,
  KpiStateDetailsInterface,
  SafetyRecommendationComponentDetailsInterface,
  SafetyRecommendationDataInterface,
  SystemIngestionStatistics,
  WarrantyKPIDataInterface,
  WarrantyKPIEnum,
  WarrantyKPIParamsInterface,
} from '@twaice-fe/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { energyAnalyticsActions } from '../actions';

export const ENERGY_ANALYTICS_FEATURE_KEY = 'energy-analytics';

export interface RemoteState {
  isLoading: boolean;
  error: string | null;
}

//region Safety Recommendation state
export interface SafetyRecommendationDataState extends RemoteState {
  systemState: string;
  numberOfStableStrings: number;
  requiredActions: EntityState<ComponentStateInterface>;
  recommendedActions: EntityState<ComponentStateInterface>;
}

export interface SafetyRecommendationComponentDetailsState {
  componentBk: string;
  kpiStates: KpiStateDetailsInterface[];
  isLoading: boolean;
  error: string | null;
}

export const safetyActionAdapter: EntityAdapter<ComponentStateInterface> = createEntityAdapter<ComponentStateInterface>({
  selectId: (safetyActionData) => safetyActionData.componentBk,
});

export const initialSafetyRecommendationDataState: SafetyRecommendationDataState = {
  systemState: '',
  numberOfStableStrings: 0,
  requiredActions: safetyActionAdapter.getInitialState(),
  recommendedActions: safetyActionAdapter.getInitialState(),
  error: null,
  isLoading: false,
};

export const initialSafetyRecommendationComponentDetailsState: SafetyRecommendationComponentDetailsState = {
  componentBk: '',
  kpiStates: [],
  error: null,
  isLoading: false,
};
//endregion

//region System Ingestion Statistics state
export interface SystemIngestionStatisticsState extends RemoteState {
  lastSeen: Date | null;
}

const initialSystemIngestionStatisticsState: SystemIngestionStatisticsState = {
  isLoading: false,
  error: null,
  lastSeen: null,
};
//endregion

//region Warranty tracker state
export interface WarrantyKPIDataState extends RemoteState {
  data: WarrantyKPIDataInterface;
}

const initialWarrantyKPIDataState: WarrantyKPIDataState = {
  data: {
    // @ts-ignore
    kpi: '',
    customerBk: '',
    systemBk: '',
    warrantySensorValues: [],
    warrantyReferenceValues: [],
  },
  isLoading: false,
  error: null,
};
//endregion

export interface State {
  customerBk: string;
  systemBk: string;
  safetyRecommendationData: SafetyRecommendationDataState;
  systemIngestionStatistics: SystemIngestionStatisticsState;
  safetyRecommendationComponentDetails: SafetyRecommendationComponentDetailsState;
  warrantyKPIData: {
    [WarrantyKPIEnum.EQUIVALENT_FULL_CYCLES]: WarrantyKPIDataState;
    [WarrantyKPIEnum.STATE_OF_HEALTH]: WarrantyKPIDataState;
    [WarrantyKPIEnum.AVAILABILITY]: WarrantyKPIDataState;
  };
}

export const initialState: State = {
  customerBk: '',
  systemBk: '',
  safetyRecommendationData: { ...initialSafetyRecommendationDataState },
  systemIngestionStatistics: { ...initialSystemIngestionStatisticsState },
  safetyRecommendationComponentDetails: { ...initialSafetyRecommendationComponentDetailsState },
  warrantyKPIData: {
    [WarrantyKPIEnum.EQUIVALENT_FULL_CYCLES]: {
      ...initialWarrantyKPIDataState,
      data: { ...initialWarrantyKPIDataState.data, kpi: WarrantyKPIEnum.EQUIVALENT_FULL_CYCLES },
    },
    [WarrantyKPIEnum.STATE_OF_HEALTH]: {
      ...initialWarrantyKPIDataState,
      data: { ...initialWarrantyKPIDataState.data, kpi: WarrantyKPIEnum.STATE_OF_HEALTH },
    },
    [WarrantyKPIEnum.AVAILABILITY]: {
      ...initialWarrantyKPIDataState,
      data: { ...initialWarrantyKPIDataState.data, kpi: WarrantyKPIEnum.AVAILABILITY },
    },
  },
};

type ActionReducer<T = unknown> = (state: State, payload: T) => State;

//region Safety Recommendation reducers
const reduceFetchSafetyRecommendationData: ActionReducer = (state) => ({
  ...state,
  safetyRecommendationData: { ...initialSafetyRecommendationDataState, isLoading: true },
});

const reduceLoadSafetyRecommendationDataSuccess: ActionReducer<{
  safetyRecommendationData: SafetyRecommendationDataInterface;
}> = (state, { safetyRecommendationData }) => ({
  ...state,
  safetyRecommendationData: {
    ...state.safetyRecommendationData,
    ...safetyRecommendationData,
    requiredActions: safetyActionAdapter.upsertMany(
      safetyRecommendationData.requiredActions,
      state.safetyRecommendationData.requiredActions
    ),
    recommendedActions: safetyActionAdapter.upsertMany(
      safetyRecommendationData.recommendedActions,
      state.safetyRecommendationData.recommendedActions
    ),
    isLoading: false,
    error: null,
  },
});

const reduceLoadSafetyRecommendationDataFailure: ActionReducer<{ error: string }> = (state, { error }) => ({
  ...state,
  safetyRecommendationData: {
    ...state.safetyRecommendationData,
    isLoading: false,
    error,
  },
});

const reduceFetchSafetyRecommendationComponentDetails: ActionReducer<{ componentBk: string }> = (state, { componentBk }) => ({
  ...state,
  safetyRecommendationComponentDetails: { ...initialSafetyRecommendationComponentDetailsState, componentBk, isLoading: true },
});

const reduceLoadSafetyRecommendationComponentDetailsSuccess: ActionReducer<{
  safetyRecommendationComponentDetails: SafetyRecommendationComponentDetailsInterface;
}> = (state, { safetyRecommendationComponentDetails }) => ({
  ...state,
  safetyRecommendationComponentDetails: {
    ...state.safetyRecommendationComponentDetails,
    ...safetyRecommendationComponentDetails,
    isLoading: false,
    error: null,
  },
});

const reduceLoadSafetyRecommendationDetailsFailure: ActionReducer<{ error: string }> = (state, { error }) => ({
  ...state,
  safetyRecommendationComponentDetails: {
    ...state.safetyRecommendationComponentDetails,
    isLoading: false,
    error,
  },
});
//endregion

//region  System Ingestion Statistics reducers
const reduceFetchSystemIngestionStatisticsData: ActionReducer = (state) => ({
  ...state,
  systemIngestionStatistics: { ...initialSystemIngestionStatisticsState, isLoading: true },
});

const reduceLoadSystemIngestionStatisticsDataSuccess: ActionReducer<{ systemIngestionStatistics: SystemIngestionStatistics }> = (
  state,
  { systemIngestionStatistics }
) => ({
  ...state,
  systemIngestionStatistics: { ...state.systemIngestionStatistics, ...systemIngestionStatistics, isLoading: false, error: null },
});

const reduceLoadSystemIngestionStatisticsDataFailure: ActionReducer<{ error: string }> = (state, { error }) => ({
  ...state,
  systemIngestionStatistics: {
    ...state.systemIngestionStatistics,
    isLoading: false,
    error,
  },
});
//endregion

//region  Warranty tracker reducers
const reduceFetchWarrantyKPIData: ActionReducer<WarrantyKPIParamsInterface> = (state, params) => ({
  ...state,
  warrantyKPIData: {
    ...state.warrantyKPIData,
    [params.kpi]: { ...state.warrantyKPIData[params.kpi], isLoading: true, error: null },
  },
});

const reduceLoadWarrantyKPIDataDataSuccess: ActionReducer<{ warrantyKPIData: WarrantyKPIDataInterface }> = (
  state,
  { warrantyKPIData }
) => ({
  ...state,
  warrantyKPIData: {
    ...state.warrantyKPIData,
    [warrantyKPIData.kpi]: {
      data: {
        ...state.warrantyKPIData[warrantyKPIData.kpi].data,
        ...warrantyKPIData,
      },
      isLoading: false,
      error: null,
    },
  },
});

const reduceLoadWarrantyKPIDataDataFailure: ActionReducer<{ kpi: WarrantyKPIEnum; error: string }> = (state, { kpi, error }) => ({
  ...state,
  warrantyKPIData: {
    ...state.warrantyKPIData,
    [kpi]: {
      ...state.warrantyKPIData[kpi],
      isLoading: false,
      error,
    },
  },
});
//endregion

export const energyAnalyticsReducer = createReducer(
  initialState,
  on(energyAnalyticsActions.fetchSafetyRecommendationData, reduceFetchSafetyRecommendationData),
  on(energyAnalyticsActions.loadSafetyRecommendationDataSuccess, reduceLoadSafetyRecommendationDataSuccess),
  on(energyAnalyticsActions.loadSafetyRecommendationDataFailure, reduceLoadSafetyRecommendationDataFailure),
  on(energyAnalyticsActions.fetchSafetyRecommendationComponentDetails, reduceFetchSafetyRecommendationComponentDetails),
  on(
    energyAnalyticsActions.loadSafetyRecommendationComponentDetailsSuccess,
    reduceLoadSafetyRecommendationComponentDetailsSuccess
  ),
  on(energyAnalyticsActions.loadSafetyRecommendationComponentDetailsFailure, reduceLoadSafetyRecommendationDetailsFailure),
  on(energyAnalyticsActions.fetchSystemIngestionStatisticsData, reduceFetchSystemIngestionStatisticsData),
  on(energyAnalyticsActions.loadSystemIngestionStatisticsSuccess, reduceLoadSystemIngestionStatisticsDataSuccess),
  on(energyAnalyticsActions.loadSystemIngestionStatisticsFailure, reduceLoadSystemIngestionStatisticsDataFailure),
  on(energyAnalyticsActions.fetchWarrantyKPIData, reduceFetchWarrantyKPIData),
  on(energyAnalyticsActions.loadWarrantyKPIDataSuccess, reduceLoadWarrantyKPIDataDataSuccess),
  on(energyAnalyticsActions.loadWarrantyKPIDataFailure, reduceLoadWarrantyKPIDataDataFailure)
);
