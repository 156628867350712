/*
 * Conventional values of time length in ms
 * */

import {
  SYSTEM_ID_ENERGY_DEMO,
  SYSTEM_ID_ENERGY_DEMO_UK,
  SYSTEM_ID_ENERGY_US_DEMO_1,
  SYSTEM_ID_ENERGY_US_DEMO_2,
  SYSTEM_ID_ENERGY_US_DEMO_3,
  SYSTEM_ID_PERCIVAL,
} from '@twaice-fe/shared/constants';
import { subDays } from 'date-fns';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TimeRangeInterface } from '../../../../frontend/shared/ui-components/src/lib/time-selector/time-selector.models';

export enum TimeLength {
  ZERO = 0,
  SECOND = 1000,
  HALF_MINUTE = 30000,
  MINUTE = 60000,
  QUARTER_HOUR = 900000,
  HOUR = 3600000,
  QUARTER_DAY = 21600000,
  HALF_DAY = 43200000,
  DAY = 86400000,
  WEEK = 604800000,
}

export enum TimeInterval {
  ZERO = 0,
  SECOND = 1000,
  HALF_MINUTE = 30000,
  MINUTE = 60000,
  QUARTER_HOUR = 900000,
  HOUR = 3600000,
  QUARTER_DAY = 21600000,
  HALF_DAY = 43200000,
  DAY = 86400000,
  SEVEN_DAYS = 604800000,
  THIRTY_DAYS = 2592000000,
  //eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ONE_WEEK = 604800000,
  THREE_MONTHS = 7890000000,
  SIX_MONTHS = 15780000000,
  NINE_MONTHS = 23670000000,
  ONE_YEAR = 31536000000,
  THREE_YEARS = 31536000000 * 3,
}

export const getCurrentDateBasedOnSystemIDInStringFormat = (systemID: string): string | null => {
  switch (systemID) {
    // Workaround for Sembcorp
    case SYSTEM_ID_PERCIVAL:
      return '2022-10-20T00:00:00';

    // Workaround for energy demo (Birch)
    case SYSTEM_ID_ENERGY_DEMO:
      return '2022-09-01T00:00:00';

    // Workaround for energy demo uk (Oak)
    case SYSTEM_ID_ENERGY_DEMO_UK:
      return '2023-12-01T00:00:00+00:00';

    // Workaround for energy demo us-1 (Elm)
    case SYSTEM_ID_ENERGY_US_DEMO_1:
      return '2024-04-01T00:00:00+00:00';
    // Workaround for energy demo us-2 (Cactus)
    case SYSTEM_ID_ENERGY_US_DEMO_2:
      return '2024-03-24T00:00:00+00:00';
    // Workaround for energy demo us-3 (Redwood)
    case SYSTEM_ID_ENERGY_US_DEMO_3:
      return '2024-04-09T00:00:00+00:00';

    default:
      return null;
  }
};
export const getTodayBasedOnSystemID = (systemID: string): number => {
  const currentDateBasedOnSystemIDInStringFormat = getCurrentDateBasedOnSystemIDInStringFormat(systemID);
  return currentDateBasedOnSystemIDInStringFormat ? Date.parse(currentDateBasedOnSystemIDInStringFormat) : Date.now();
};

export const getTimeRangeBasedOnSystemID = (
  systemID: string,
  timeResolution: TimeInterval
): { startDate: string; endDate: string } => {
  const today = getTodayBasedOnSystemID(systemID);

  return {
    startDate: new Date(today - timeResolution).toISOString().slice(0, 19),
    endDate: new Date(today).toISOString().slice(0, 19),
  };
};

export const getTimeRangeBasedOnSystemIDInUnixTimestamp = (
  systemID: string,
  timeResolution: TimeInterval
): { startDate: number; endDate: number } => {
  const today = getTodayBasedOnSystemID(systemID);

  return {
    startDate: today - timeResolution,
    endDate: today,
  };
};

export const getCurrentDateBasedOnSystemID = (systemID: string): Date => {
  const currentDateBasedOnSystemIDInStringFormat = getCurrentDateBasedOnSystemIDInStringFormat(systemID);
  return currentDateBasedOnSystemIDInStringFormat ? new Date(currentDateBasedOnSystemIDInStringFormat) : new Date();
};

export const getUnixTimestampFromDate = (date: Date): number => Math.floor(date.getTime() / 1000);

export enum TimeRangeEnum {
  CUSTOM = 0,
  LAST_SEVEN_DAYS = 1,
  LAST_THIRTY_DAYS = 2,
  LAST_SIXTY_DAYS = 3,
  LAST_NINETY_DAYS = 4,
  LAST_THREE_HUNDRED_SIXTY_FIVE_DAYS = 5,
}

/* eslint-disable @typescript-eslint/naming-convention */
export const getRanges = (systemID: string): TimeRangeInterface[] => [
  {
    id: 'custom',
    value: [getCurrentDateBasedOnSystemID(systemID), getCurrentDateBasedOnSystemID(systemID)],
    label: 'Custom',
    disabled: true,
  },
  {
    id: 'sevenDays',
    value: [subDays(getCurrentDateBasedOnSystemID(systemID), 7), getCurrentDateBasedOnSystemID(systemID)],
    label: 'Last 7 days',
    disabled: false,
  },
  {
    id: 'thirtyDays',
    value: [subDays(getCurrentDateBasedOnSystemID(systemID), 30), getCurrentDateBasedOnSystemID(systemID)],
    label: 'Last 30 days',
    disabled: false,
  },
  {
    id: 'sixtyDays',
    value: [subDays(getCurrentDateBasedOnSystemID(systemID), 60), getCurrentDateBasedOnSystemID(systemID)],
    label: 'Last 60 days',
    disabled: false,
  },
  {
    id: 'ninetyDays',
    value: [subDays(getCurrentDateBasedOnSystemID(systemID), 90), getCurrentDateBasedOnSystemID(systemID)],
    label: 'Last 90 days',
    disabled: false,
  },
  {
    id: 'threeHundredSixtyFiveDays',
    value: [subDays(getCurrentDateBasedOnSystemID(systemID), 365), getCurrentDateBasedOnSystemID(systemID)],
    label: 'Last 365 days',
    disabled: true,
  },
];

export const areDatesEqual = (date1: Date, date2: Date): boolean =>
  date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
