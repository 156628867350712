/* eslint-disable @typescript-eslint/naming-convention -- API defined structures */
import { TWResponse } from './response.models';
import { KpiAlertModels } from './kpi-alert.models';
import { IsDateString, IsDefined } from '@twaice-fe/shared/utilities';

export enum KPI {
  STATE_OF_HEALTH_CAPACITY = 'soh-c',
  EQUIVALENT_FULL_CYCLES = 'efc',
  DIRECT_CURRENT_RESISTANCE = 'dcr',
  MODULE_TEMPERATURE_SPREADS = 'module-temperature-spread',
  CELL_VOLTAGE_SPREADS = 'cell-voltage-spread',
}

export enum AggregationType {
  AVG = 'avg',
  COUNT = 'count',
  MAX = 'max',
  MIN = 'min',
  NONE = 'none',
  SUM = 'sum',
}

export enum AggregationScope {
  ACROSS_ENTITIES = 'across-entities',
  PER_ENTITY = 'per-entity',
}

export enum Sampling {
  DAY = '1d',
  HOUR = '1h',
  MINUTE = '1m',
  RAW = 'raw',
  WEEK = '1w',
}

export class BaseHealthParameters {
  system_id: string;
  aggregation_scope: AggregationScope;
  aggregation_type: AggregationType;
  depth?: number;
}

export class HealthParameters extends BaseHealthParameters {
  start_date: string;
  end_date: string;
  sampling: Sampling;
}

export class HealthContainer {
  container_name: string;
  container_id: string;
  sensor_values: number[];
  sensor_timestamps: string[];
  sensor_id?: string;
  sensor_name?: string;
  standard_sensor_bk?: string;
}

export class HealthContainerV2 {
  component_bk?: string;
  component_name?: string;
  standard_sensor_bk?: string;
  value?: number;
  timestamp?: string;
  sensor_values?: number[];
  sensor_timestamps?: string[];
  sensor_name?: string;
}

export class Health {
  system_id: string;
  containerID?: string;
  depth: number;
  kpi: string;
  unit: string;
  start_date?: string;
  end_date?: string;
  aggregation_scope: AggregationScope;
  aggregation_type: AggregationType;
  data: HealthContainer[];
  upperAreaConfidenceInterval?: HealthContainer;
  lowerAreaConfidenceInterval?: HealthContainer;
}

export class HealthV2 {
  customer_bk: string;
  system_bk: string;
  component_bk?: string;
  level_bk: string;
  kpi: string;
  unit: string;
  start_date?: string;
  end_date?: string;
  aggregation_scope: AggregationScope;
  aggregation_type: AggregationType;
  data: HealthContainerV2[];
  upperAreaConfidenceInterval?: HealthContainerV2;
  lowerAreaConfidenceInterval?: HealthContainerV2;
}

export class HealthResponse extends TWResponse {
  data: Health;
}

export class HealthResponseV2 {
  status: string;
  data: HealthV2;
}

export class HealthRequestParameters {
  kpi: KPI;
  aggregationType: AggregationType;
  useLatest?: boolean;
  multiply?: boolean;
  multiplyBy?: number;
  startDate?: string;
  endDate?: string;
  sampling?: Sampling;
  sampling_seconds?: number;
  aggregationScope?: AggregationScope;
  kpiName?: string;
}

export class HealthAlert {
  kpi: string;
  alert: KpiAlertModels;
  containerId: string;
}

export class HealthRequestMeasurementParameters {
  @IsDefined()
  @IsDateString()
  startDate: string;
  @IsDefined()
  @IsDateString()
  endDate: string;
  @IsDefined()
  sampling: Sampling;
  sampling_seconds?: number;
}

export enum SingleRackKpisEnum {
  STATE_OF_HEALTH = 'soh-c',
  TEMPERATURE = 'temperature',
  STATE_OF_CHARGE = 'soc',
  CELL_VOLTAGE_SPREADS = 'cell-voltage-spread',
  DIRECT_CURRENT_RESISTANCE = 'dcr',
  EQUIVALENT_FULL_CYCLES = 'efc',
  MODULE_TEMPERATURE_SPREADS = 'module-temperature-spread',
}

export type PartialRecord<K extends keyof never, T> = {
  [P in K]?: T;
};
