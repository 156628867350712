import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { groupBy, map, mergeMap, switchMap } from 'rxjs';

import { Store } from '@ngrx/store';

import { HealthV2Service } from '@twaice-fe/frontend/shared/services';
import { HealthV2, HealthAlert } from '@twaice-fe/shared/models';
import {
  healthKpisDataRequestConfig,
  healthKpisLatestDataRequestConfig,
} from 'libs/frontend/features/energy-analytics/health-v2/src/lib/kpis-distribution/kpis-distribution.config';
import { KpisDistributionService } from 'libs/frontend/features/energy-analytics/health-v2/src/lib/services/kpis-distribution.service';
import { DistributionKpisEnum } from 'libs/frontend/features/energy-prediction/src/lib/models/storage-overview.models';
import { healthV2Action } from '../actions';

@Injectable()
export class HealthEffectsV2 {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthV2Action.fetchHealthKpiData),
      // Group actions by their kpi value
      groupBy(({ requestParams }) => requestParams.kpi),
      // Handle each kpi group in parallel
      mergeMap((group$) =>
        group$.pipe(
          // Within each kpi group, switch to new requests (cancelling any ongoing ones)
          switchMap(({ requestParams, customerBk, systemBk, aggregationScope, levelBk }) =>
            this.healthService
              .getHealthData({
                requestParams,
                customerBk,
                systemBk,
                aggregationScope,
                levelBk,
              })
              .pipe(
                map((health: HealthV2 | HealthAlert) => {
                  const kpiName = health.kpi as DistributionKpisEnum;
                  const kpisChartData: any = {};
                  const kpisChartDataInfo: any = {};
                  const kpisChartMissingDataInfo: any = {};

                  if (health instanceof HealthAlert) {
                    kpisChartData[kpiName] = { alert: health.alert };
                    kpisChartDataInfo[kpiName] = [];
                    kpisChartMissingDataInfo[kpiName] = {};
                  } else {
                    const healthKpi = health;
                    const kpiDistributionData = this.kpisDistributionService.createKpiDistributionData({
                      health: healthKpi,
                      healthRequestParameters: [...healthKpisLatestDataRequestConfig, ...healthKpisDataRequestConfig],
                    });
                    kpisChartData[kpiName] = this.kpisDistributionService.createHealthKpiChartData(kpiDistributionData);
                    this.kpisDistributionService.createHealthKpiTableData([], kpiDistributionData);
                    kpisChartDataInfo[kpiName] = this.kpisDistributionService.createKpiInfoChartData({
                      health: healthKpi,
                      healthRequestParameters: [...healthKpisLatestDataRequestConfig, ...healthKpisDataRequestConfig],
                    });
                    const kpiDataCount = kpiDistributionData?.kpiTableIds
                      ? Object.keys(kpiDistributionData.kpiTableIds).length
                      : 0;
                    const missingDataCount = kpiDistributionData?.kpiChartData
                      ? kpiDataCount - kpiDistributionData?.kpiChartData?.length
                      : 0;
                    kpisChartMissingDataInfo[kpiName] = {
                      dataCount: kpiDataCount,
                      missingDataCount: missingDataCount,
                    };
                  }

                  return healthV2Action.loadHealthKpiDataSuccess({
                    data: { kpisChartData, kpisChartDataInfo, kpisChartMissingDataInfo },
                  });
                })
              )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private healthService: HealthV2Service,
    private kpisDistributionService: KpisDistributionService,
    protected store: Store
  ) {}
}
