import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { SystemsService } from '@twaice-fe/frontend/shared/services';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  of,
  pairwise,
  switchMap, take,
  takeUntil,
  withLatestFrom
} from 'rxjs';

import { Router } from '@angular/router';
import {CustomerType, PaginatedResponse} from '@twaice-fe/shared/models';
import { systemActions } from '../actions';
import * as SystemsActions from '../actions/systems.actions';
import { systemSelectors } from '../selectors';
import * as ConfigSelectors from '../selectors/configs.selectors';
import * as SystemSelectors from '../selectors/systems.selectors';

@Injectable()
export class SystemsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[SystemsActions.fetchSystems, SystemsActions.fetchSystemsHealthKpiData]),
      withLatestFrom(this.store.select(SystemSelectors.getSystemsState), this.store.select(ConfigSelectors.getCustomerType)),
      mergeMap(([action, state, customerType]) => {
        let { page, limit } = state?.config ?? { page: 1, limit: 10 };
        limit = limit ?? 10;
        page = page ?? 1;

        const { type, ...includes } = action;
        return this.systemService
          .fetchSystems({ page, limit, ...includes, onlySystems: customerType !== CustomerType.ENERGY })
          .pipe(
            map(({ data }) =>
              type === SystemsActions.fetchSystemsHealthKpiData({}).type
                ? SystemsActions.loadSystemsHealthKpiDataSuccess({ data })
                : SystemsActions.loadSystemsSuccess({ data })
            ),
            takeUntil(this.actions$.pipe(ofType(SystemsActions.cancelSystemRequest))),
            catchError((error) => {
              console.error('Error', error);
              return of(SystemsActions.loadSystemsFailure({ error }));
            })
          );
      })
    )
  );

  systemEfcData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemsActions.fetchSystemsEfcData),
      take(1),
      exhaustMap((action) => {
        return this.systemService
          .fetchSystemEfcData({
            system: action.systems.map((system) => ({
              customerBk: system.customerBk,
              systemBk: system.systemBk,
            })),
          })
          .pipe(
            map(({ data }) => {
              return SystemsActions.loadSystemsEfcDataSuccess({
                data: new PaginatedResponse({
                  items: data,
                  next: null,
                  page: 1,
                  pageSize: data.length,
                  prev: null,
                  totalPages: 1,
                  totalItems: data.length,
                }),
              });
            }),
            take(1),
            catchError((error) => {
              return of(SystemsActions.loadSystemsFailure({ error }));
            })
          );
      })
    )
  );

  systemSohData$ = createEffect(() =>
  this.actions$.pipe(
      ofType(SystemsActions.fetchSystemsSohData),
      take(1),
      exhaustMap((action) => {
        return this.systemService.fetchSystemSohData({
          system: action.systems.map((system) => ({
            customerBk: system.customerBk,
            systemBk: system.systemBk
          }))
        }).pipe(
          map(({ data }) => {
            return SystemsActions.loadSystemsSohDataSuccess({
              data: new PaginatedResponse({
                items: data,
                next: null,
                page: 1,
                pageSize: data.length,
                prev: null,
                totalPages: 1,
                totalItems: data.length
              })
            });
          }),
          take(1),
          catchError((error) => {
            return of(SystemsActions.loadSystemsFailure({ error }));
          })
        );
      })
    )
  );

  // redirect to new system route when system changes
  selectSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemsActions.selectSystem),
      withLatestFrom(this.store.select(systemSelectors.getSelected).pipe(pairwise())),
      switchMap(([_, [previousSystem, currentSystem]]) => {
        if (!previousSystem || previousSystem.systemBk === currentSystem?.systemBk) {
          return of(systemActions.systemRouteRedirectSuccess());
        }

        const routeSegments = this.router.routerState.snapshot.url.split('/');

        // replace old systemBK in route with new one and drop query params, handle edgecase where customerBk === systemBk
        const newRoute = routeSegments.map((segment, i) =>
          segment === previousSystem?.systemBk && routeSegments[i - 1] === 'system'
            ? currentSystem?.systemBk
            : segment.split('?')[0]
        );

        this.router.navigate(newRoute, {
          queryParamsHandling: 'merge',
        });

        return of(systemActions.systemRouteRedirectSuccess());
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private router: Router,
    protected store: Store,
    private systemService: SystemsService
  ) {}
}