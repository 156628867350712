<ul
  nz-menu
  nzMode="inline"
  nzTheme="light"
  [nzInlineCollapsed]="isCollapsed"
  (nzCollapsedChange)="collapse($event)"
  *ngIf="showSideNav"
  class="h-full flex flex-col border-none"
>
  <ng-container [ngSwitch]="customerType">
    <!-- ENERGY -->
    <ng-container *ngSwitchCase="CustomerType.ENERGY">
      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/energy/overview')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Overview' : ''"
        [attr.data-intercom-target]="intercomTarget + '-asset-dashboard-solution'"
      >
        <a
          class="overwrite-link-styling flex items-center gap-8 mr-24"
          [routerLink]="'/energy/overview'"
          (click)="preventDefault($event)"
        >
          <twaice-fe-svg-icon name="home" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Overview</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/performance-manager')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Performance' : ''"
        [attr.data-intercom-target]="intercomTarget + '-performance-manager-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/performance-manager'">
          <twaice-fe-svg-icon name="chart-arrows-vertical" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }" class="mr-32">Performance</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/safety')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Safety' : ''"
        [attr.data-intercom-target]="intercomTarget + '-safety-v2'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/safety'">
          <twaice-fe-svg-icon name="alert-triangle" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Safety</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/energy/health')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Health' : ''"
        [attr.data-intercom-target]="intercomTarget + '-health-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8 h-40" [routerLink]="'/energy/health'">
          <twaice-fe-svg-icon nz-icon name="icon_health" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Health</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/health-prediction/health-prediction')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Prediction' : ''"
        *featureToggle="'ENERGY_NAVIGATION_SHOW_PREDICTION'"
        [attr.data-intercom-target]="intercomTarget + '-health-prediction-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/health-prediction/health-prediction'">
          <twaice-fe-svg-icon name="icon_health_prediction" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Prediction</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/energy/warranty')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Warranty' : ''"
        [attr.data-intercom-target]="intercomTarget + '-warranty-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/energy/warranty'">
          <twaice-fe-svg-icon name="icon_warranty" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Warranty</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/warranty-tracker')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Warranty V2' : ''"
        *featureToggle="'ENERGY_SHOW_WARRANTY_TRACKER_V2'"
        [attr.data-intercom-target]="intercomTarget + '-warranty-tracker-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/warranty-tracker'">
          <twaice-fe-svg-icon name="icon_warranty" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Warranty V2</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/storage-monitoring/data-explorer')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Data Explorer' : ''"
        [attr.data-intercom-target]="intercomTarget + '-data-explorer-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/storage-monitoring/data-explorer'">
          <twaice-fe-svg-icon name="icon_data_explorer" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Data Explorer</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/system-monitoring')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Monitoring' : ''"
        [attr.data-intercom-target]="intercomTarget + '-safety-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/system-monitoring'">
          <twaice-fe-svg-icon name="heart-rate-monitor" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Monitoring</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/reports')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Reports' : ''"
        *featureToggle="'ENERGY_REPORTS_V2'"
        [attr.data-intercom-target]="intercomTarget + '-reports-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8 h-40" [routerLink]="'/reports'">
          <twaice-fe-svg-icon name="file-analytics" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Reports</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/reporting/library')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Reports' : ''"
        [attr.data-intercom-target]="intercomTarget + '-reports-solution'"
        *featureToggle="'!ENERGY_REPORTS_V2'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/reporting/library'">
          <twaice-fe-svg-icon name="file-analytics" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Reports</span>
        </a>
      </li>
    </ng-container>

    <ng-container *ngSwitchCase="CustomerType.MOBILITY">
      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/mobility-explore/overview')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Home' | translate: { _key: 'fleet.nav.home' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-home'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/mobility-explore/overview'">
          <twaice-fe-svg-icon name="home" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">{{ 'Home' | translate: { _key: 'fleet.nav.home' } }}</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="isPartOfExploreAndCompare"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Explore & Compare' | translate: { _key: 'fleet.nav.explore' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-explore-compare-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/mobility-explore/table'">
          <twaice-fe-svg-icon name="directions" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">{{ 'Explore & Compare' | translate: { _key: 'fleet.nav.explore' } }}</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/mobility/alerts')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Safety' | translate: { _key: 'fleet.nav.safety' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-safety-solution'"
        *featureToggle="'FLEET_ANALYTICS_ENABLE_SAFETY'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/mobility/alerts'">
          <twaice-fe-svg-icon name="alert-triangle" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">{{ 'Safety' | translate: { _key: 'fleet.nav.safety' } }}</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/reporting/library')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Reports' | translate: { _key: 'fleet.nav.reports' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-reports-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/reporting/library'">
          <twaice-fe-svg-icon name="file-analytics" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">{{ 'Reports' | translate: { _key: 'fleet.nav.reports' } }}</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="routeIsActive('/mobility/config')"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Configuration' | translate: { _key: 'fleet.nav.config' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-configuration-solution'"
      >
        <a class="overwrite-link-styling flex items-center gap-8" [routerLink]="'/mobility/config'">
          <twaice-fe-svg-icon name="icon_tool" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }" class="mr-24">
            {{ 'Configuration' | translate: { _key: 'fleet.nav.config' } }}
          </span>
        </a>
      </li>
    </ng-container>
  </ng-container>

  <li
    nz-tooltip
    nzTooltipPlacement="right"
    [nzTooltipTitle]="isCollapsed ? ('Expand' | translate: { _key: 'fleet.nav.expand' }) : ''"
    class="mt-auto border-solid border-t-1 border-slate-500 py-16"
    [ngClass]="{ 'flex items-center pl-24': !isCollapsed, 'pl-32': isCollapsed }"
    [attr.data-intercom-target]="intercomTarget + '-collapse-sidebar-button'"
  >
    <a class="overwrite-link-styling flex items-center gap-8" (click)="collapse(!isCollapsed)">
      <twaice-fe-svg-icon
        [name]="isCollapsed ? 'layout-sidebar-right-collapse' : 'layout-sidebar-left-collapse'"
        source="tabler"
        width="16px"
        height="16px"
      />
      <span [ngClass]="{ invisible: isCollapsed }">{{ 'Collapse' | translate: { _key: 'fleet.nav.collapse' } }}</span>
    </a>
  </li>
</ul>
