import * as sharedStore from '../index';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, groupBy, map, mergeMap, of, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { EnergyAnalyticsService } from '@twaice-fe/frontend/shared/services';
import * as energyAnalyticsActions from '../actions/energy-analytics.actions';
import { Store } from '@ngrx/store';

const { systemSelectors } = sharedStore.selectors;

@Injectable()
export class EnergyAnalyticsEffects {
  //region Safety Recommendation effects
  fetchSafetyRecommendationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(energyAnalyticsActions.fetchSafetyRecommendationData),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      filter(([_, system]) => system != null),
      switchMap(([_, system]) =>
        this.energyAnalyticsService
          .fetchSafetyRecommendationData({ customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            map((safetyRecommendationData) =>
              energyAnalyticsActions.loadSafetyRecommendationDataSuccess({ safetyRecommendationData })
            ),
            takeUntil(this.actions$.pipe(ofType(energyAnalyticsActions.cancelSafetyRecommendationDataRequest))),
            catchError((error) => {
              console.error('[Error/Fetch safety recommendation]', error);
              return of(energyAnalyticsActions.loadSafetyRecommendationDataFailure({ error }));
            })
          )
      )
    )
  );

  fetchSafetyRecommendationComponentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(energyAnalyticsActions.fetchSafetyRecommendationComponentDetails),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      filter(([_, system]) => system != null),
      switchMap(([params, system]) =>
        this.energyAnalyticsService
          .fetchSafetyRecommendationComponentDetails({
            customerBk: system?.customerBk,
            systemBk: system?.systemBk,
            componentBk: params.componentBk,
          })
          .pipe(
            map((safetyRecommendationComponentDetails) =>
              energyAnalyticsActions.loadSafetyRecommendationComponentDetailsSuccess({ safetyRecommendationComponentDetails })
            ),
            takeUntil(this.actions$.pipe(ofType(energyAnalyticsActions.cancelSafetyRecommendationComponentDetailsRequest))),
            catchError((error) => {
              console.error('[Error/Fetch safety recommendation component details]', error);
              return of(energyAnalyticsActions.loadSafetyRecommendationComponentDetailsFailure({ error }));
            })
          )
      )
    )
  );
  //endregion

  //region  System Ingestion Statistics effects
  fetchSystemIngestionStatisticsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(energyAnalyticsActions.fetchSystemIngestionStatisticsData),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      filter(([_, system]) => system != null),
      switchMap(([_, system]) =>
        this.energyAnalyticsService
          .fetchSystemIngestionStatistics({ customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            map((statistics) =>
              energyAnalyticsActions.loadSystemIngestionStatisticsSuccess({ systemIngestionStatistics: statistics })
            ),
            takeUntil(this.actions$.pipe(ofType(energyAnalyticsActions.cancelSystemIngestionStatisticsRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system ingestion statistics]', error);
              return of(energyAnalyticsActions.loadSystemIngestionStatisticsFailure({ error }));
            })
          )
      )
    )
  );
  //endregion

  //region  Warranty tracker effects
  fetchWarrantyKPIData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(energyAnalyticsActions.fetchWarrantyKPIData),
      // Group actions by their kpi value
      groupBy((requestParams) => requestParams.kpi),
      // Handle each kpi group in parallel
      mergeMap((group$) =>
        group$.pipe(
          withLatestFrom(this.store.select(systemSelectors.getSelected)),
          filter(([_, system]) => system != null),
          switchMap(([params, system]) =>
            this.energyAnalyticsService
              .fetchWarrantyKPIData({
                customerBk: system?.customerBk,
                systemBk: system?.systemBk,
                kpi: params.kpi,
                startDate: params.startDate,
                endDate: params.endDate,
                granularity: params.granularity,
              })
              .pipe(
                map((warrantyKPIData) => energyAnalyticsActions.loadWarrantyKPIDataSuccess({ warrantyKPIData })),
                catchError((error) => {
                  console.error('[Error/Fetch Warranty tracker kpi data ]', error);
                  return of(energyAnalyticsActions.loadWarrantyKPIDataFailure({ kpi: params.kpi, error }));
                }),
                takeUntil(this.actions$.pipe(ofType(energyAnalyticsActions.cancelWarrantyKPIDataRequest)))
              )
          )
        )
      )
    )
  );
  //endregion

  constructor(
    private readonly actions$: Actions,
    private energyAnalyticsService: EnergyAnalyticsService,
    protected store: Store
  ) {}
}
