import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeSelectorComponent } from './time-selector.component';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';

const NG_ZORRO_MODULES = [NzDatePickerModule, NzSegmentedModule, NzSelectModule];

@NgModule({
  declarations: [TimeSelectorComponent],
  imports: [CommonModule, FormsModule, ...NG_ZORRO_MODULES],
  exports: [TimeSelectorComponent],
})
export class TimeSelectorModule {}
