import { ChartInfoItemContent, ChartMissingDataContent } from '@twaice-fe/frontend/shared/ui-components';
import { ChartLayout } from '@twaice-fe/frontend/shared/utilities';
import { AggregationType, HealthRequestParameters, KPI } from '@twaice-fe/shared/models';
import { DistributionKpisEnum, KPIChartData, KpiDistributionInterface } from '../models/kpis-distribution.models';

export const healthKpisLatestDataRequestConfig: HealthRequestParameters[] = [
  {
    kpiName: DistributionKpisEnum.STATE_OF_HEALTH_CAPACITY,
    kpi: KPI.STATE_OF_HEALTH_CAPACITY,
    aggregationType: AggregationType.NONE,
    useLatest: true,
  },
  {
    kpiName: DistributionKpisEnum.EQUIVALENT_FULL_CYCLES,
    kpi: KPI.EQUIVALENT_FULL_CYCLES,
    aggregationType: AggregationType.NONE,
    useLatest: true,
  },
  {
    kpiName: DistributionKpisEnum.DIRECT_CURRENT_RESISTANCE,
    kpi: KPI.DIRECT_CURRENT_RESISTANCE,
    aggregationType: AggregationType.NONE,
    multiply: true,
    multiplyBy: 1000,
    useLatest: true,
  },
];

export const healthKpisDataRequestConfig: HealthRequestParameters[] = [
  {
    kpiName: DistributionKpisEnum.CELL_VOLTAGE_SPREADS,
    kpi: KPI.CELL_VOLTAGE_SPREADS,
    aggregationType: AggregationType.MAX,
    multiply: true,
    multiplyBy: 1000,
  },
  {
    kpiName: DistributionKpisEnum.MODULE_TEMPERATURE_SPREADS,
    kpi: KPI.MODULE_TEMPERATURE_SPREADS,
    aggregationType: AggregationType.MAX,
  },
];

export const kpisChartLayoutConfig: Record<DistributionKpisEnum, ChartLayout> = {
  sohC: {
    title: 'Latest State of Health',
    numberFormat: '1.1-1',
    xTickValuesPrecision: 1,
    yLabel: 'Number of strings',
    xLabel: 'Distribution (%)',
    tooltipTitle: 'SoH',
  },
  efc: {
    title: 'Equivalent Full Cycles',
    numberFormat: '1.0-0',
    xTickValuesPrecision: 0,
    yLabel: 'Number of strings',
    xLabel: 'Distribution by latest values',
    tooltipTitle: 'EFC',
  },
  cellVoltageSpread: {
    title: 'Voltage Spread',
    numberFormat: '1.1-1',
    xTickValuesPrecision: 1,
    yLabel: 'Number of strings',
    xLabel: 'Distribution by maximum values in the last 24 hours (mV)',
    tooltipTitle: 'Voltage Spread',
  },
  dcr: {
    title: 'Latest DC Resistance',
    numberFormat: '1.1-1',
    xTickValuesPrecision: 1,
    yLabel: 'Number of strings',
    xLabel: 'Distribution (mΩ)',
    tooltipTitle: 'DCR',
  },
  moduleTemperatureSpread: {
    title: 'Temperature Spread',
    numberFormat: '1.1-1',
    xTickValuesPrecision: 1,
    yLabel: 'Number of strings',
    xLabel: 'Distribution by maximum values in the last 24 hours (°C)',
    tooltipTitle: 'Temperature Spread',
  },
};

export const kpisChartInfoConfig: Record<DistributionKpisEnum, AggregationType[]> = {
  sohC: [AggregationType.MIN, AggregationType.MAX, AggregationType.NONE],
  efc: [AggregationType.MIN, AggregationType.MAX, AggregationType.NONE],
  cellVoltageSpread: [AggregationType.MIN, AggregationType.MAX, AggregationType.NONE],
  dcr: [AggregationType.MIN, AggregationType.MAX, AggregationType.NONE],
  moduleTemperatureSpread: [AggregationType.MIN, AggregationType.MAX, AggregationType.NONE],
};

export const initKpisChartDataConfig: Record<DistributionKpisEnum, KPIChartData> = {
  sohC: {},
  efc: {},
  cellVoltageSpread: {},
  dcr: {},
  moduleTemperatureSpread: {},
};

export const initKpisChartDataInfoConfig: Record<DistributionKpisEnum, ChartInfoItemContent[] | null> = {
  sohC: null,
  efc: null,
  cellVoltageSpread: null,
  dcr: null,
  moduleTemperatureSpread: null,
};

export const initKpisChartMissingDataInfoConfig: Record<DistributionKpisEnum, ChartMissingDataContent | null> = {
  sohC: null,
  efc: null,
  cellVoltageSpread: null,
  dcr: null,
  moduleTemperatureSpread: null,
};



export const sortDataByConfig = (
  data: KpiDistributionInterface[],
  key: keyof KpiDistributionInterface,
  direction: 'asc' | 'desc'
): KpiDistributionInterface[] => {
  const isAscending = direction === 'asc';

  return [...data].sort((a, b) => {
    const valA = a[key];
    const valB = b[key];

    if (valA === undefined) return isAscending ? -1 : 1;
    if (valB === undefined) return isAscending ? 1 : -1;

    if (typeof valA === 'number' && typeof valB === 'number') {
      return isAscending ? valA - valB : valB - valA;
    }

    if (typeof valA === 'string' && typeof valB === 'string') {
      return isAscending
        ? valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' })
        : valB.localeCompare(valA, undefined, { numeric: true, sensitivity: 'base' });
    }

    return 0; // Return a neutral value if unexpected type encountered or if both values are of the same type but not number or string.
  });
}
