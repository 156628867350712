<div class="flex items-center gap-16">
  <nz-range-picker
    nzFormat="MMM d, y"
    [(ngModel)]="pickerValue"
    (ngModelChange)="onDatePickerChange($event)"
    [nzRenderExtraFooter]="footerRender"
    [nzMode]="selectedTimeResolution?.value ?? 'date'"
  ></nz-range-picker>
  <div class="flex items-center gap-4" *ngIf="showGranularity">
    <span class="font-normal text-sm leading-base text-tertiary-text">Granularity</span>
    <nz-select class="w-[100px]" [(ngModel)]="selectedGranularity" (ngModelChange)="onGranularityChange($event)">
      <nz-option *ngFor="let granularity of granularityOptions" [nzValue]="granularity" [nzLabel]="granularity | titlecase">
      </nz-option>
    </nz-select>
  </div>
  <ng-template #footerRender>
    <div class="flex flex-col gap-16 px-[4px] py-16">
      <span class="text-base font-semibold">View resolution</span>
      <nz-segmented
        [nzBlock]="true"
        [nzOptions]="timeResolutionMapping"
        [(ngModel)]="selectedTimeResolutionIndex"
        (ngModelChange)="onTimeResolutionChange($event)"
      ></nz-segmented>
      <span class="text-base font-semibold">Time range</span>
      <nz-segmented
        [nzOptions]="timeRangeMapping"
        [(ngModel)]="selectedTimeRangeIndex"
        (ngModelChange)="onTimeRangeChange($event)"
        [nzBlock]="false"
      ></nz-segmented>
    </div>
  </ng-template>
</div>
