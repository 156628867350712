export class UiConfig {
  customerId: string;
  featureFlags: string[] | FeatureFlagsEnum[];
  customerType: CustomerType;
  solutions: Solution[];
  customerBk: string;
}

export class LoginConfig {
  aws_region: string;
  user_pool_id: string;
  app_client_id: string;
  sso_config?: {
    identityProvider: string;
    domain: string;
    allowedHost: string;
    redirectUrl: string;
  };
}

export enum CustomerType {
  ENERGY = 'ENERGY',
  MOBILITY = 'MOBILITY',
  MODELS = 'MODELS',
  // Only used as a fallback if config is missing or invalid
  UNKNOWN = 'UNKNOWN',
}

export enum Solution {
  ENERGY = 'ENERGY',
  MOBILITY = 'MOBILITY',
  MODEL_LIBRARY = 'MODEL_LIBRARY',
  MODEL_DEMO = 'MODEL_DEMO',
}

export enum FeatureFlagsEnum {
  ENERGY_PERFORMANCE_MANAGER_SHOW_BALANCING = 'ENERGY_PERFORMANCE_MANAGER_SHOW_BALANCING',
  ENERGY_PERFORMANCE_MANAGER_SHOW_BALANCING_SELECTOR = 'ENERGY_PERFORMANCE_MANAGER_SHOW_BALANCING_SELECTOR',
  ENERGY_PERFORMANCE_MANAGER_SHOW_TEMPERATURE = 'ENERGY_PERFORMANCE_MANAGER_SHOW_TEMPERATURE',
  ENERGY_NAVIGATION_SHOW_PREDICTION = 'ENERGY_NAVIGATION_SHOW_PREDICTION',
  ENERGY_SHOW_WARRANTY_TRACKER_V2 = 'ENERGY_SHOW_WARRANTY_TRACKER_V2',
  ENERGY_REPORTS_V2 = 'ENERGY_REPORTS_V2',
  FLEET_ANALYTICS_HIDE_WARRANTY = 'FLEET_ANALYTICS_HIDE_WARRANTY',
  FLEET_ANALYTICS_ENABLE_SAFETY = 'FLEET_ANALYTICS_ENABLE_SAFETY',
}

export interface ConfigsInterface {
  featureFlags: string[] | FeatureFlagsEnum[];
  solutions: Solution[];
}
