import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import {
  FeStorageMetadata,
  FeWarrantyConditionsMetadata,
  SidebarMetadata,
  KPIDataPoint,
  KPIWidgetData,
  WarrantyChartData,
} from './models/warranty-tracker.models';
import {
  ContainerNode,
  DESensor,
  EnergyAnalyticsMetaData,
  FeWarrantyMetadata,
  HttpParametersObject,
  Measurements,
  TwResponse,
  WarrantyOverviewKPI,
  WarrantyReferenceData,
  WarrantyReferenceQuery,
  WarrantyTrackerInterface,
} from '@twaice-fe/shared/models';
import { catchError, map } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class WarrantyTrackerService {
  private readonly POSITIVE_COLOR = '#48bb78';
  private readonly NEGATIVE_COLOR = '#f56565';

  constructor(private httpClient: HttpClient, private decimalPipe: DecimalPipe) {}

  generateWarrantyMetadata(storageMetadata: EnergyAnalyticsMetaData, warrantyMetadata: FeWarrantyMetadata): SidebarMetadata {
    let storageInformation: FeStorageMetadata;

    let warrantyConditions: FeWarrantyConditionsMetadata;

    if (storageMetadata) {
      storageInformation = {
        initialCapacity: {
          name: 'Nominal Storage Capacity',
          value: storageMetadata.nominalEnergyCapacity,
        },
        power: {
          name: 'Maximum Rated Power',
          value: storageMetadata.maximumPower,
        },
        batteryCapacity: {
          name: 'Nominal Cell Capacity',
          value: storageMetadata.batteryType,
        },
      };
    }

    if (warrantyMetadata) {
      warrantyConditions = {
        soh: {
          name: 'State of Health',
          value:
            warrantyMetadata.sohAtEol && warrantyMetadata.lifeInYears
              ? `${this.decimalPipe.transform(warrantyMetadata.sohAtEol, '1.0-2')}% after ${warrantyMetadata.lifeInYears} years`
              : null,
        },
        cycles: {
          name: 'Cycles',
          value:
            warrantyMetadata.cyclesUntilEol && warrantyMetadata.lifeInYears
              ? `${this.decimalPipe.transform(warrantyMetadata.cyclesUntilEol, '1.0-1')} cycles after ${
                  warrantyMetadata.lifeInYears
                } years`
              : null,
        },
      };
    }

    return {
      storageInformation,
      warrantyConditions,
    };
  }

  getReferenceData(
    sensorName: string,
    dateFrom: number,
    dateTo: number,
    warrantyReferenceMetadata: FeWarrantyMetadata
  ): Observable<WarrantyReferenceData> {
    const params: WarrantyReferenceQuery = {
      ...warrantyReferenceMetadata,
      dateFrom,
      dateTo,
    };
    const url = `warranty-tracker/reference-data?sensorName=${sensorName}&metadata=${JSON.stringify(params)}`;
    return this.httpClient.get<WarrantyReferenceData>(url);
  }

  /**
   * Request all sensors for a specific containerID
   * then fill the sensor in KPIWidgetData in the respective KPI
   *
   * @param systemID
   * @param rackID rack id
   * @param kpiData object data to be updated
   */
  getWarrantyKpiSensors(systemID: string, rackID: string, kpiData: KPIWidgetData): Observable<KPIWidgetData> {
    return this.httpClient.get<DESensor[]>(`data-explorer/containers/${rackID}/sensors?systemID=${systemID}&virtual=true`).pipe(
      map((containerSensors) => {
        kpiData.kpiList.forEach((kpi) => {
          kpi.sensor = containerSensors.find((containerSensor) => containerSensor.name.includes(kpi.sensorName));
        });

        if (kpiData.subKpiList && kpiData.subKpiList.length) {
          kpiData.subKpiList.forEach((kpi) => {
            kpi.sensor = containerSensors.find((containerSensor) => containerSensor.name.includes(kpi.sensorName));
          });
        }

        return kpiData;
      }),
      catchError(() => of(kpiData))
    );
  }

  /**
   * filter sensors tids by sensorName from list of sensors contained in the requested container
   *
   * @private
   * @param systemID
   * @param kpiData
   * @param dateFrom
   * @param dateTo
   * @param timeResolution
   */
  getSensorsData(
    systemID: string,
    kpiData: KPIWidgetData,
    dateFrom: Date,
    dateTo: Date,
    timeResolution: number
  ): Observable<WarrantyChartData> {
    const sensorKpiList: KPIDataPoint[] = [];
    const observableArray: Observable<Measurements[]>[] = [];

    kpiData.kpiList.forEach((kpi) => {
      if (kpi.sensor) {
        sensorKpiList.push(kpi);
      }
    });

    if (kpiData.subKpiList && kpiData.subKpiList.length) {
      kpiData.subKpiList.forEach((kpi) => {
        if (kpi.sensor) {
          sensorKpiList.push(kpi);
        }
      });
    }

    sensorKpiList.forEach((kpi: KPIDataPoint) => {
      const requestParams: HttpParametersObject = {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- API defined naming
        system_id: systemID,
        tids: kpi.sensor.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- API defined naming
        start_range: dateFrom.toISOString(),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- API defined naming
        end_range: dateTo.toISOString(),
        agg: kpi.aggregation ? kpi.aggregation : 'avg',
        sampling: timeResolution.toString() + 'ms',
      };

      if (kpi.applySpecificOutlierFilter) {
        requestParams.applySpecificOutlierFilter = kpi.applySpecificOutlierFilter.toString();
      }

      if (kpi.filterOutliers) {
        requestParams.filter_outlier = kpi.filterOutliers.toString();
      }

      const observableItem = this.httpClient.get<Measurements[]>(`data-explorer/measurements`, { params: requestParams });
      observableArray.push(observableItem);
    });

    const warrantyChartData: WarrantyChartData = {
      x: [],
      y: [],
      chartColors: kpiData.kpiListColors,
      unit: kpiData.unit,
    };

    return forkJoin(observableArray).pipe(
      map((result) => {
        const sortedMeasurementsByTids = [];

        sensorKpiList.forEach((kpi: KPIDataPoint) => {
          sortedMeasurementsByTids.push(result.flat().find((measurement) => measurement.tid === kpi.sensor.id));
        });

        sortedMeasurementsByTids.forEach((measurements: Measurements) => {
          if (
            measurements?.timestamps &&
            measurements?.timestamps.length &&
            measurements?.values &&
            measurements?.values.length
          ) {
            warrantyChartData.x.push(measurements.timestamps.map((time) => Date.parse(time)));
            warrantyChartData.y.push(measurements.values);
          }
        });

        return warrantyChartData;
      }),
      catchError(() => of(null))
    );
  }

  /**
   * Get array of container nodes at a levelDepth
   *
   * @private
   * @param systemID
   * @param containerID
   * @param levelDepth
   */
  getContainerTree(systemID: string, containerID: string, levelDepth: number): Observable<ContainerNode[]> {
    if (!levelDepth) return of(null);

    const url = `warranty-tracker/container-tree?systemID=${systemID}&containerID=${containerID}&levelDepth=${levelDepth}`;

    return this.httpClient.get<ContainerNode[]>(url);
  }

  /**
   *
   * @param systemID
   * @param levelDepth
   * @param warrantyReferenceMetadata: FeWarrantyMetadata
   * @returns Observable<TwResponse<WarrantyTrackerInterface>
   */
  getWarrantyOverview(
    systemID: string,
    levelDepth: number,
    warrantyReferenceMetadata: FeWarrantyMetadata
  ): Observable<TwResponse<WarrantyTrackerInterface>> {
    if (!levelDepth) return of(null);

    const url = `warranty-tracker/overview-kpi-list/${systemID}?levelDepth=${levelDepth}&metadata=${encodeURIComponent(
      JSON.stringify(warrantyReferenceMetadata)
    )}`;

    return this.httpClient.get<TwResponse<WarrantyTrackerInterface>>(url).pipe(
      map((response) => this.processWarrantyOverviewResponse(response)),
      catchError((error) => {
        console.error('Error fetching warranty tracker overview:', error);
        return of(null);
      })
    );
  }

  private processWarrantyOverviewResponse(response: TwResponse<WarrantyTrackerInterface>): TwResponse<WarrantyTrackerInterface> {
    return new TwResponse<WarrantyTrackerInterface>({
      ...response,
      data: {
        ...response.data,
        items: response.data.items.map((item) => this.processWarrantyTrackerItem(item)),
      },
    });
  }

  private processWarrantyTrackerItem(item: WarrantyTrackerInterface): WarrantyTrackerInterface {
    const processDeviation = (current: number, reference: number) => {
      const deviation = current - reference;
      return {
        deviation,
        color: deviation >= 0 ? this.POSITIVE_COLOR : this.NEGATIVE_COLOR,
        sign: deviation >= 0 ? '+' : '',
      };
    };

    if (item.soh && item.sohReference) {
      const { deviation, color, sign } = processDeviation(item.soh, item.sohReference);
      item.sohDeviation = deviation;
      item.sohDeviationColor = color;
      item.sohDeviationSign = sign;
    }

    if (item.cycles && item.cyclesReference) {
      const { deviation, color, sign } = processDeviation(item.cyclesReference, item.cycles);
      item.cyclesDeviation = deviation;
      item.cyclesDeviationColor = color;
      item.cyclesDeviationSign = sign;
    }

    item.id = item.fullName;

    return item;
  }
}
