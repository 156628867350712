import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ENERGY_ANALYTICS_FEATURE_KEY, safetyActionAdapter, State } from '../reducers/energy-analytics.reducer';
import { ActionStateEnum, DataInterface, WarrantyKPIEnum } from '@twaice-fe/shared/models';
import { roundToPrecision } from '@twaice-fe/shared/utilities';

export const getEnergyAnalyticsState = createFeatureSelector<State>(ENERGY_ANALYTICS_FEATURE_KEY);

// region Safety Recommendation selectors
const safetyActionSelectors = safetyActionAdapter.getSelectors();
export const getRequiredActions = createSelector(getEnergyAnalyticsState, (state) =>
  safetyActionSelectors.selectAll(state.safetyRecommendationData.requiredActions)
);
export const getRequiredActionsEntities = createSelector(getEnergyAnalyticsState, (state) =>
  safetyActionSelectors.selectEntities(state.safetyRecommendationData.requiredActions)
);

export const getRecommendedActions = createSelector(getEnergyAnalyticsState, (state) =>
  safetyActionSelectors.selectAll(state.safetyRecommendationData.recommendedActions)
);

export const getRecommendedActionsEntities = createSelector(getEnergyAnalyticsState, (state) =>
  safetyActionSelectors.selectEntities(state.safetyRecommendationData.recommendedActions)
);

export const getSafetyRecommendationError = createSelector(
  getEnergyAnalyticsState,
  (state) => state.safetyRecommendationData.error
);
export const getSafetyRecommendationLoadingState = createSelector(
  getEnergyAnalyticsState,
  (state) => state.safetyRecommendationData.isLoading
);

export const getSafetyRecommendationOverviewState = createSelector(getEnergyAnalyticsState, (state) => ({
  [ActionStateEnum.REQUIRED]: state.safetyRecommendationData.requiredActions.ids.length,
  [ActionStateEnum.RECOMMENDED]: state.safetyRecommendationData.recommendedActions.ids.length,
  [ActionStateEnum.STABLE]: state.safetyRecommendationData.numberOfStableStrings,
}));

export const getComponentByComponentBk = ({ componentBk, actionType }: { componentBk: string; actionType: ActionStateEnum }) =>
  createSelector(
    actionType === ActionStateEnum.REQUIRED ? getRequiredActionsEntities : getRecommendedActionsEntities,
    (entities) => entities[componentBk] || undefined
  );

export const getSafetyRecommendationComponentDetails = createSelector(
  getEnergyAnalyticsState,
  (state) => state.safetyRecommendationComponentDetails
);

export const getSafetyRecommendationComponentDetailsEntities = createSelector(
  getSafetyRecommendationComponentDetails,
  (state) => state.kpiStates
);

export const getSafetyRecommendationComponentDetailsError = createSelector(
  getSafetyRecommendationComponentDetails,
  (state) => state.error
);

export const getSafetyRecommendationComponentDetailsLoadingState = createSelector(
  getSafetyRecommendationComponentDetails,
  (state) => state.isLoading
);
//endregion

//region System Ingestion Statistics selectors
export const getSystemIngestionStatisticsLastSeenTimestamp = createSelector(
  getEnergyAnalyticsState,
  (state) => state.systemIngestionStatistics.lastSeen
);
//endregion

//region Warranty tracker selectors
export const getWarrantyKPIData = (kpi: WarrantyKPIEnum) =>
  createSelector(getEnergyAnalyticsState, (state) => ({
    ...state.warrantyKPIData[kpi],
    data: {
      ...state.warrantyKPIData[kpi].data,
      warrantySensorValues: transformValues(state.warrantyKPIData[kpi].data.warrantySensorValues, warrantyKPIPrecision[kpi]),
      warrantyReferenceValues: transformValues(
        state.warrantyKPIData[kpi].data.warrantyReferenceValues,
        warrantyKPIPrecision[kpi]
      ),
    },
    isLoading: state.warrantyKPIData[kpi].isLoading,
  }));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformValues = (sensorValues: any[], precision: number): any[] =>
  sensorValues.map((sensorValue) => ({
    ...sensorValue,
    data: sensorValue.data.map((dataPoint: DataInterface) => ({
      ...dataPoint,
      value: roundToPrecision(dataPoint.value, precision),
    })),
  }));

export const warrantyKPIPrecision: Record<WarrantyKPIEnum, number> = {
  [WarrantyKPIEnum.EQUIVALENT_FULL_CYCLES]: 0,
  [WarrantyKPIEnum.STATE_OF_HEALTH]: 2,
  [WarrantyKPIEnum.AVAILABILITY]: 2,
};
//endregion
