import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DataNotFoundError,
  TWSingleResponse,
  BaseEnergyAnalyticsParamsInterface,
  SafetyRecommendationDataInterface,
  SafetyRecommendationComponentDetailsParamsInterface,
  SafetyRecommendationComponentDetailsInterface,
  SystemIngestionStatistics,
  WarrantyKPIDataInterface,
  WarrantyKPIParamsInterface,
} from '@twaice-fe/shared/models';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { keysToCamel, keysToSnake } from '@twaice-fe/shared/utilities';
import { objectToHttpParams } from '@twaice-fe/frontend/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class EnergyAnalyticsService {
  constructor(private httpClient: HttpClient) {}

  // region Safety Recommendation service functions
  fetchSafetyRecommendationData(params: BaseEnergyAnalyticsParamsInterface): Observable<SafetyRecommendationDataInterface> {
    const url = this.buildUrl('analytics/safety/actions-overview', [params.customerBk, params.systemBk]);

    return this.fetchData<BaseEnergyAnalyticsParamsInterface, SafetyRecommendationDataInterface>(url);
  }

  fetchSafetyRecommendationComponentDetails(
    params: SafetyRecommendationComponentDetailsParamsInterface
  ): Observable<SafetyRecommendationComponentDetailsInterface> {
    const url = this.buildUrl('analytics/safety/actions-details', [params.customerBk, params.systemBk, params.componentBk]);

    return this.fetchData<SafetyRecommendationComponentDetailsParamsInterface, SafetyRecommendationComponentDetailsInterface>(
      url
    );
  }
  //endregion

  //region System Ingestion Statistics service functions
  fetchSystemIngestionStatistics(params: BaseEnergyAnalyticsParamsInterface): Observable<SystemIngestionStatistics> {
    const url = this.buildUrl('analytics/systems', [params.customerBk, params.systemBk, 'ingestion-statistics']);

    return this.fetchData<BaseEnergyAnalyticsParamsInterface, SystemIngestionStatistics>(url);
  }
  //endregion

  //region Warranty tracker service functions
  fetchWarrantyKPIData(params: WarrantyKPIParamsInterface): Observable<WarrantyKPIDataInterface> {
    const url = this.buildUrl('analytics/warranty', [params.customerBk, params.systemBk, params.kpi]);

    return this.fetchData<unknown, WarrantyKPIDataInterface>(url, {
      startDate: params.startDate,
      endDate: params.endDate,
      granularity: params.granularity,
    });
  }
  //endregion

  private fetchData<T, R>(url: string, params?: T): Observable<R> {
    const httpParams = params ? objectToHttpParams(keysToSnake(params)) : undefined;

    return this.httpClient.get<TWSingleResponse<R>>(url, { params: httpParams }).pipe(
      map(({ data }) => {
        if (!(data as any)) {
          throw new DataNotFoundError(`No data found for the given parameters.`);
        }
        return keysToCamel(data);
      }),
      catchError((error: Error) => {
        console.error(`Error occurred while fetching data from ${url} with params: ${params?.toString()}`, error.message);
        return throwError(() => error);
      })
    );
  }

  private buildUrl(endpoint: string, params?: (string | number)[]): string {
    return params ? `${endpoint}/${params.join('/')}` : `${endpoint}`;
  }
}
