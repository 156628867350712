import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sortDataByConfig } from 'libs/frontend/features/energy-analytics/health-v2/src/lib/kpis-distribution/kpis-distribution.config';
import { KpiDistributionInterface } from 'libs/frontend/features/energy-analytics/health-v2/src/lib/models/kpis-distribution.models';
import { HEALTH_FEATURE_KEY_V2, State, StringsDistributionState, stringsDistributionAdapter } from '../reducers/health-v2.reducer';

const { selectAll } = stringsDistributionAdapter.getSelectors();

export const getHealthState = createFeatureSelector<State>(HEALTH_FEATURE_KEY_V2);
export const getStringsDistributionState = createSelector(getHealthState, (state) => state.stringsDistribution);

export const getRawDistributionList = createSelector(getStringsDistributionState, selectAll);
export const getSelectedStringsDistributionIds = createSelector(getStringsDistributionState, (state) => state.selectedIds ?? []);

export const getSelectedStringsDistributionItems = createSelector(
  getStringsDistributionState,
  (state) => state?.selectedIds?.map((id) => ({ id, name: state.entities[id]?.containerName })) ?? []
);

export const getStringsDistribution = createSelector(getStringsDistributionState, (state: StringsDistributionState) => state);

export const getHealthKpiData = createSelector(getHealthState, (state) => state.healthKpis);

export const getStringsDistributionList = createSelector(getStringsDistributionState, (state: any, props: { page: number; pageSize: number; }) => {
  let data = selectAll(state);

  if (Object.keys(state.customFilters ?? {}).length) {
    data = filterDistributionList(data, state.customFilters);
  }

  if (state.config.searchString) {
    data = data.filter((item) => searchInAllKeys(item, state.config.searchString));
  }

  if (state.config.order) {
    const [key, direction] = state.config.order.split(':');
    data = sortDataByConfig(data, key as keyof KpiDistributionInterface, direction as 'asc' | 'desc');
  }

  const start = (props.page - 1) * props.pageSize;
  const end = start + props.pageSize;
  return { data: data.slice(start, end), totalCount: data.length }
});

function searchInAllKeys(item: KpiDistributionInterface, searchString: string): boolean {
  if (!searchString) return false;

  const formattedSearchString = searchString.trim().toLowerCase();

  const valueStr = String(item.containerName).toLowerCase();
  return valueStr.includes(formattedSearchString);
}

function filterDistributionList(healthKpiList: KpiDistributionInterface[], kpiFilter: any): KpiDistributionInterface[] {
  const kpiName = Object.keys(kpiFilter)[0];
  const filters = kpiFilter[kpiName];

  return healthKpiList.reduce((result, val) => {
    for (const filter of filters) {
      // @ts-ignore
      if (
        filter &&
        ((filter.min === 0 && filter.max === 0 && val[kpiName] == null) ||
          (val[kpiName] >= filter.min && val[kpiName] < filter.max))
      ) {
        // @ts-ignore
        result.push(val);
        break;
      }
    }
    return result;
  }, []);
}
